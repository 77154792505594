import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'


const AndroidApp = () => {
  const params=useParams()
  const [apps, setApps]=useState([])
  const baseURL = sessionStorage.getItem("apipathurl");
  useEffect(()=>{
      const getApp=async()=>{
          const serverData= await fetchApp()
          setApps(serverData)
      }
      getApp()
  },[]) 
  const fetchApp=async()=>{
      const res=await fetch(baseURL+'/api-back/app/')//in production replace 'http://127.0.0.1:800 with baseURL+'
      const fetchedData=res.json()
      return fetchedData
  }
  return (
    <>
    <div className='row pt-5'>
      <div className='col text-center'>
        <h4 id="app" className='font-effect-fire-animation'>Android App Collection</h4>
      </div>
    </div>
    <div className='row row-cols-sm-3 g-4 ' >
      {apps.map((ap)=>
        <div className='col-xs-12 col-sm  my-3' key={ap.id}>
          <div className="card mx-auto shadow" style={{width:"18rem"}}>
            <a href={ap.link} ><img className="card-img-top" src={ap.img} alt={ap.alt}/></a>
            <div className="card-body">
              <a href={ap.link} ><h5 className="card-title">{ap.title}</h5></a>
              <p className="card-text">{ap.body}</p>
            </div>
          </div>
        </div>
      )}
    </div>
    { params.home === 'Back' &&
    <div className='row'>
        <div className='col text-center'>
            <Link to='/'><button type='button' className='btn btn-primary' >{params.home}</button></Link>
        </div>
    </div>}
    </>
  )
}
export default AndroidApp
