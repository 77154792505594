import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BackEnd from './components/BackEnd';
import FrontEnd from './components/FrontEnd';
import AndroidApp from './components/AndroidApp';
import Utility from './components/Utility';
import NotFound from './components/NotFound';
import ScrollToTop from './components/ScrollToTop';

function App() {
  sessionStorage.setItem("apipathurl","https://march2022.qizhonglabs.com")
  return (
    <div className="container-fluid bk">
      <Header />
      <BrowserRouter >
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Content/>}/>
          <Route exact path="/fullstack/:home" element={<BackEnd/>}/>
          <Route exact path="/websites/:home" element={<FrontEnd/>}/>
          <Route exact path="/programs/:home" element={<Utility/>}/>
          <Route exact path="/androidapps/:home" element={<AndroidApp/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
        </ScrollToTop>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
